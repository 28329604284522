export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Abgerny Incredibox",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.gamenora.com/splash/incredibox-abgerny/",
    domain: "abgernyincredibox.com",
    gaId: "G-LYZCK7XCDV",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
